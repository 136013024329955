/* eslint-disable react-hooks/exhaustive-deps */
import adapt from '@turnaroundfactor/common'
import { RoomProvider, useSession, useSettings } from '@turnaroundfactor/react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Logo from '../../components/Logo'
import getLocalStream from '../../utils/getLocalStream'
import Room from './Room'

export default function RoomMain(){
  const { settings, update: updateSettings } = useSettings();
  const { user, signedIn } = useSession();
  const { sidebar } = settings;
  const { roomId } = useParams();
  const [ hasRoom, setHasRoom ] = useState(false);
  const roomRef = useRef(null);
  const node = useRef(null);

  useLayoutEffect(()=>{
    if( user === undefined || user === null || user === false ){
      console.log(`Capture previous room ID: ${roomId}`);
      updateSettings({ prevRoom: roomId });
    }
  }, [user]);

  useEffect(()=>{
    if( roomId ){
      adapt.getRoom(roomId).then(room => {
        roomRef.current = room;
        getLocalStream().then(stream => {
          console.debug(`Media stream was configured.`);
          roomRef.current.mediaStream = stream;
          roomRef.current.user = adapt.user;
          setHasRoom(true);
        });
        
      });
    }

  }, []);
  

  if( user === undefined || user === null || user === false || signedIn === false ){
    return <Navigate to="/login" />;
  }

  if ( !roomId ) {
    return <Navigate to="/" />;
  }

  return (
    <div id="app" className={sidebar === true ? '-sidebar' : ''} ref={node}>
      { hasRoom === true ? (
        <RoomProvider room={roomRef.current}>
          <Room />
        </RoomProvider> ) : (
        <div id="connection_main">
            <div id="connect" className="p-4 bg-white rounded d-flex flex-column align-items-center justify-content-center shadow-sm">
              <Logo />
              <h4 className="pt-2 mb-1">Connecting to Room</h4>
              <p className="small mb-4">Loading....</p>
            </div>
        </div>)
      }
    </div>
  )
}

