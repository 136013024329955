import adapt from '@turnaroundfactor/common';
import logger from './logging';
import { updateSettings } from '@turnaroundfactor/react';
import store from '../data/store';

const WEBCAM_CONSTRAINTS = {
  video: {
    aspectRatio: 1.7777777778,
    frameRate: 15,
    width: {
      max: 640,
      ideal: 640
    },
    height: {
      max: 480, 
      ideal: 360
    },
    facingMode: "user"
  },
  audio: {
    echoCancellation: true
  }
};

let localStream = null;

function extractDevices( stream ) {
  const audioTrack = stream.getAudioTracks()[0];
  const videoTrack = stream.getVideoTracks()[0];
  
  return { 
    audioDevice: audioTrack.getCapabilities().deviceId, 
    videoDevice: videoTrack.getCapabilities().deviceId 
  };
}

const getLocalStream = async ()=>{
  const { app } = store.getState();
  const { audioDevice, videoDevice } = app;
  
  if( localStream !== null && localStream.active === true ){
    let active = true;
    localStream.getTracks().forEach(t => {
      if( t.readyState === 'ended' ) active = false;
    });

    const {
      audioDevice: activeAudio,
      videoDevice: activeVideo
    } = extractDevices(localStream);

    if( active === true && (activeAudio === audioDevice && activeVideo === videoDevice) ) return localStream;
  }

  const constraints = WEBCAM_CONSTRAINTS;

  if ( videoDevice ) constraints.video.deviceId = { exact: videoDevice };
  if ( audioDevice ) constraints.audio = { deviceId: { exact: audioDevice }, echoCancellation: true };

  try {
    localStream = await navigator.mediaDevices.getUserMedia(constraints);
    adapt.mediaStream = localStream; 
    const devices = extractDevices(localStream);
    adapt.trigger('STREAM_CAPTURE');
    updateSettings(devices);
    return localStream;
  }catch (e) {
    localStream = null;
    if ( e.name === 'NotAllowedError' ) {
      updateSettings({ mediaAllowed: false, audioEnabled: false, videoEnabled: false });
    }
    logger.error(`Failed to capture local stream: ${e.message}`);
    return null;
  }
}

window.getLocalStream = getLocalStream;

export default getLocalStream;