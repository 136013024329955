import adapt, { emailService } from '@turnaroundfactor/common';
import axios from 'axios';
import { useState } from 'react';

const userBase = {
  type: "RemoteExpert",
  user_class: "WebUI"
}

const defaultError = 'Error signing in, please try again later';

export function useGoogleAuth(onSuccess) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  const handler = async (response) => {
    if( response && response.error ){
      console.log(`Google auth error [ ${response.error} ] - ${response.details}`);
      setError('Google login failed, please try again.');
      setActive(false);
      return false;
    }

    const { credential } = response;
    const [,token] = credential.split('.');
    const payload = JSON.parse(atob(token));
    const { email, name } = payload;

    setActive(true);
    setError(null);

    try{
      const user = await adapt.login({ 
        method: 'google',
        email,
        name,
        token: credential,
        ...userBase
      });

      if( user === false || user === null ) { 
        setError('Google auth failed. Please try again');
        setActive(false);
        return false;
      }

      await user.refresh();
      onSuccess && onSuccess(user);

    }catch(e){
      setError(e?.responseBody || e?.message || defaultError);
      setActive(false);
      return;
    }

    setActive(false);
  }

  return {
    active,
    error,
    handler
  }
}


export function useCACAuth(onSuccess) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  const handler = async ()=>{
    setActive(true);
    setError(null);

    try{
      const user = await adapt.login({
        method: 'cac',
        ...userBase
      });

      if( user === false || user === null ) { 
        setError("CAC Auth failed, please try again.");
        setActive(false);
        return false;
      }

      onSuccess && onSuccess(user);
      
    }catch(e){
      console.log({e});
      setError(e?.responseBody || e?.message || defaultError);
      setActive(false);
      return;
    }

    setActive(false);
  }

  return {
    active,
    error,
    handler
  }
}

export function useOTPAuth(onSuccess) {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);

  const reset = () => {
    setError(null);
    setActive(false);
  }

  const handler = async ({ code, name, email })=>{
    setActive(true);
    setError(null);

    let token;

    try{
      const { data } = await axios.post(emailService('receive'), { 
        n: name,
        e: email,
        p: code
      });

      token = data.token;
      console.log(`Login with token`, token);

    } catch (e) {
      setError('Invalid code, please try again');
      setActive(false);
      return false;
    }

    try{
      const user = await adapt.login({
        email,
        name,
        ...userBase,
        method: 'email',
        token: JSON.stringify({ data: token })
      });

      if( user === false || user === null ) { 
        setError("Authentication failed, please try again.");
        setActive(false);
        return false;
      }

      onSuccess && onSuccess(user);
      
    }catch(e){
      console.log({e});
      setError(e?.responseBody || e?.message || defaultError);
      setActive(false);
      return;
    }

    setActive(false);
  }

  return {
    active,
    error,
    handler,
    reset
  }
}