import adapt, { logService } from '@turnaroundfactor/common'
import { useRoom } from '@turnaroundfactor/react'
import React, { useEffect, useRef, useState } from 'react'
import { Collapse, Dropdown } from 'react-bootstrap'

const logLevels = {
  'error' : 'Error',
  'warn': 'Warning',
  'info': 'Info',
  'debug': 'Debug'
}

function viewAnalytics(type, options = {}) {
  options.room_id   = adapt.room?.uuid;
  options.shortname = adapt.room?.shortname;
  options.user_id   = adapt.user?.uuid;
  
  const opts = encodeURIComponent(JSON.stringify(options));
  const url = `${logService('analytics')}?type=${type}&options=${opts}`;
  
  window.open(url, '_blank')
}

function PeerTimer({ peer }){

  const timer = useRef(null);
  const node  = useRef(null);

  function updateTime(){
    if( node.current ){
      node.current.innerText = peer.duration;
      timer.current = setTimeout(updateTime, 1000);
    }
  }

  useEffect(_ =>{
    timer.current = setTimeout(updateTime, 1000);
    return _ => clearTimeout(timer.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li className="mb-1">
      <strong>Duration:</strong> <span ref={node}></span>
    </li>
  )
}

function PeerStatus({ peer }){
  const [logLevel, setLogLevel] = useState(peer.logger.level);
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(peer.connected);
  const [degraded, setDegraded] = useState(false);

  useEffect(()=>{

    let mounted = true;

    function trackConnected(){
      if( mounted === false ) return;
      
      if( peer.connected === true ){
        setConnected(true);
        setDegraded(false);
      }else{ 
        setConnected(false);
        setDegraded(false);      
      }
    } 

    peer.on('CONNECTION_DEGRADED', _ => setDegraded(true));
    peer.on('CONNECTION_ACTIVE', _ => setDegraded(false));
    peer.on('CONNECTED', trackConnected);
    peer.on('DISCONNECTED', trackConnected);

    return _ => {
      mounted = false;
      peer.off('CONNECTED', trackConnected);
      peer.off('DISCONNECTED', trackConnected);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(()=>{
    peer.logger.level = logLevel;
  }, [peer, logLevel]);


  const color = degraded === true ? 'text-warning' : ( connected === true ? 'text-success' : 'text-muted');


  return (
    <li className="mb-1 py-1 border-bottom status-item">
      <div className="d-flex align-items-center justify-content-between py-1 collapse-toggle" role="button" aria-expanded={open} onClick={e => setOpen(!open)}>
        <i className={`${connected === true ? 'fas' : 'far' } fa-circle fa-fw mr-1 ${color} status-indicator`}></i>
        <span className="text-truncate d-inline-block m-0 align-middle" data-toggle="tooltip" title={peer.uuid}>
          {peer.name}
        </span>
        <span className="badge badge-dark ml-auto">{peer.state}</span>
        <i className="fa fa-lg fa-caret-down caret ml-1"></i>
      </div>
      <Collapse in={open}>
        <ul className="list-unstyled p-2 bg-light">
          <li className="mb-1 d-flex align-items-center">
            <strong>Log Level</strong>
            <Dropdown className="flex-grow-1">
              <Dropdown.Toggle variant="outline-dark" className="py-0 ml-2 text-left" size="sm">
                {logLevels[logLevel]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="small" onClick={e => setLogLevel('error')}>Error</Dropdown.Item>
                <Dropdown.Item className="small" onClick={e => setLogLevel('warn')}>Warning</Dropdown.Item>
                <Dropdown.Item className="small" onClick={e => setLogLevel('info')}>Info</Dropdown.Item>
                <Dropdown.Item className="small" onClick={e => setLogLevel('debug')}>Debug</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="mb-1">
            <strong>ID:</strong> {peer.uuid}
          </li>
          <li className="mb-1">
            <strong>State:</strong> {peer.state}
          </li>
          <li className="mb-1">
            <strong>User Class:</strong> {peer.class}
          </li>
          <li className="mb-1">
            <strong>User Type:</strong> {peer.type}
          </li>
          { connected === true && 
          <li className="mb-1">
            <strong>Connected After:</strong> {peer.timeToConnect}
          </li> }
          { connected === true && <PeerTimer peer={peer} /> }
          { peer.connection && peer.connection.debug_name && 
          <li className="mb-1">
            <strong>Instance:</strong> {peer.connection.debug_name}
          </li> }
          <li className="mb-1">
            <button className="btn btn-sm btn-light" type="button" onClick={e => viewAnalytics('peer', { peer_id: peer.uuid })}>View logs</button>
          </li>
        </ul>
      </Collapse>
    </li>
  )
}

function StatusPane(){
  const { users, tools, nodes } = useRoom();
  const mlist = users.filter(m => m.state === 'InRoom');
  const memberlist = mlist.filter(m => m.type !== 'OnSiteUser');
  const onSite     = mlist.filter(m => m.type === 'OnSiteUser');

  const msg = (m) => <p className='small font-italic text-muted'>{m}</p>

  return (
    <section id='status_pane' className="d-flex flex-column p-3">
      <h5 className="mb-0 text-muted small font-weight-bold text-uppercase">Remote Experts</h5>
      { memberlist.length === 0 ? msg("No remote experts connected") : 
      <ul className="list-unstyled">
        { memberlist.map(m => <PeerStatus peer={m} key={m.uuid} />) }
      </ul>
      }

      <h5 className="mb-0 text-muted small font-weight-bold text-uppercase">On-Site Users</h5>
      { onSite === undefined ? msg("No on-site user detected") : 
      <ul className="list-unstyled">
        { onSite.map(m => <PeerStatus peer={m} key={m.uuid} />) }
      </ul>
      }

      <h5 className="mb-0 text-muted small font-weight-bold text-uppercase">Tools</h5>
      { tools.length === 0 ? msg("No tools connected") : 
      <ul className="list-unstyled">
        { tools.map(m => <PeerStatus peer={m} key={m.uuid} />) }
      </ul>
      }

      <h5 className="mb-0 text-muted small font-weight-bold text-uppercase">Nodes</h5>
      { nodes.length === 0 ? msg("No nodes connected") : 
      <ul className="list-unstyled">
        { nodes.map(m => <PeerStatus peer={m} key={m.uuid} />) }
      </ul>
      }

      <h5 className="mb-0 text-muted small font-weight-bold text-uppercase">ME</h5>
      <ul className="list-unstyled">
        <li className="mb-1 py-1 status-item">
            <strong>ID:</strong> {adapt.user.uuid}
        </li> 
      </ul>

      <div className="border-top pt-4">
        <h5 className="mb-2 text-muted small font-weight-bold text-uppercase">Log Analytics</h5>
        <ul className="list-unstyled">
          { ['Room', 'Signal', 'Presence'].map(t => (
            <li className="mb-2" key={t}>
              <button type="button" className="btn btn-light d-flex align-items-center w-100" onClick={e => viewAnalytics(t.toLowerCase())}>
                <i className="fa fa-info-circle mr-2"></i>
                <small>{t}</small>
              </button>
            </li>
          )) }
        </ul>
      </div>
    </section>
  )
}

export default StatusPane;