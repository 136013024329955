import adapt, { assetService, ADAPT_CONFIG } from '@turnaroundfactor/common';
import { useRoom, useSettings } from '@turnaroundfactor/react';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../../components/Toast';
import { icons } from '../../data/config';

export default function PDFViewer(){
  
  const { settings, update: updateSettings } = useSettings();
  const { activeTool, activeDocument, user } = settings;
  const { broadcast } = useRoom();

  const node = useRef(null);
  const pdf  = useRef(null);
  const savedAnnotations = useRef([]);

  function teardown(instance){
    const { PSPDFKit } = window;

    try{  
      PSPDFKit.unload(instance);
    }catch(e){}
    pdf.current = null;
  }

  async function loadDocument(){

    if( activeDocument === null ) return;
    savedAnnotations.current = [];

    teardown(pdf.current || node.current);

    const { data }  = await axios.get(assetService(`fetch?uuid=${activeDocument.uuid}`));
    const { token, documentId } = data;

    const closeDocButton = {
      type: "custom",
      id: "adapt-closer",
      title: "Close",
      icon: icons.close,
      onPress: (event)=> closePanel()
    }

    const sendToIpadButton = {
      type: "custom",
      id: "adapt-send-to-ipad",
      title: "Sync with On-Site User",
      icon: icons.sendToDevice,
      onPress: (event)=> {
        const page = instance.viewState.get('currentPageIndex');

        broadcast('DOCUMENT_SESSION', { 
          page: page,
          user: { name: user.name, uuid: user.uuid },
          document: activeDocument
        });

        const msgid = uuidv4();

        toast(<Toast title="Share request" icon="fa-file-pdf" message={`You've requested everyone view ${activeDocument.filename}, page ${page + 1}`} id={msgid} />, {
          toastId: msgid,
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          draggable: false
        });
      }
    }

    const { PSPDFKit } = window;

    const PDFEditorButtons = PSPDFKit.defaultToolbarItems.filter( item => {
      return item.type !== 'print' && item.type !== 'export-pdf';
    });
  

    const instance = await PSPDFKit.load({
      documentId,
      serverUrl: ADAPT_CONFIG.PSPDF_SERVER,
      authPayload: { jwt: token },
      instant: true,
      container: node.current,
      toolbarItems: [...PDFEditorButtons, sendToIpadButton, closeDocButton]
    });

    window.PDFEditor = instance;
    pdf.current = instance;

    return instance;
  }  

  useEffect(()=>{
    return _ => { adapt.off('DOCUMENT_SESSION'); }
  }, [updateSettings, user.uuid]);

  
  useEffect(()=>{
    const editor = pdf.current;
    teardown(pdf.current || node.current);
    if( activeDocument !== null ) loadDocument();

    return _ => teardown(editor);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDocument]);

  function closePanel(){
    updateSettings({
      activeTool: null,
      activeDocument: null,
      documentOwner: null
    });
  }


  
  return (
    <div id="pdf_viewer" className={`${activeTool === 'pdf' ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center screen-tool`}>
      <div id="pspdf_wrap" className="w-100 h-100" ref={node}></div>
    </div>
  )
}