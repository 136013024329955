/* eslint-disable react-hooks/exhaustive-deps */
import adapt from '@turnaroundfactor/common'
import { useRoom, useSettings, useSession } from '@turnaroundfactor/react'
import React, { useCallback, useEffect, useRef, } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Logo from '../../components/Logo'
import Toolbar from '../../components/Toolbar'
import BottomBar from '../../components/BottomBar'
import { Draw, Screenshot } from '../ar'
import CallScreen from '../callScreen'
import PDFViewer from '../documents/PDFViewer'
import ApproveMember from './ApproveMember'
import { enterRoomSound, roomRequestSound } from './helpers'
import Sidebar from './Sidebar'
import uploader from '../../utils/uploader';
import MediaView from '../../components/MediaView';
import DocumentPrompt from '../documents/Prompt';
import Toast from '../../components/Toast';
import updateARState from '../../utils/updateARState'

export default function Room(){
  const navigate = useNavigate();
  const node = useRef(null);

  const { settings: { 
    activeTool, 
    videoEnabled, 
    audioEnabled,
    AREnabled,
    ARFeeds,
    //pinned
  }, update: updateSettings } = useSettings();
  
  const { state, connected, subscribe, broadcast, uuid: roomId, users } = useRoom();
  const { user } = useSession();


  function cancelJoin(){
    adapt.disconnect();
    updateSettings({ room: null, prevRoom: null, pinned: null });
    navigate('/');
  }

  function handleARState(data) {
    const { requester_uuid, active } = data;
    console.log(`Received AR state change from ${requester_uuid} [${active}]`);
    if ( requester_uuid ) updateARState(requester_uuid, active);
  }

  // const handleAutoPin = useCallback((peer) => {
  //   if ( pinned === peer.uuid ) {
  //     updateSettings({ pinned: null });
  //     return;
  //   }

  //   if ( users.length === 0 && pinned === null ) {
  //     updateSettings({ pinned: peer.uuid });
  //   }
  // }, [users, pinned]);



  function handleDrag(event){
    event?.preventDefault();
    event?.stopPropagation();
  }

  async function handleFiles(event) {
    event?.preventDefault();
    event?.stopPropagation();

    const files = [...event.dataTransfer.files];
 
    files.forEach(f => {
      uploader.add({
        file: f,
        mimeType: f.type,
        size: f.size,
        filename: f.name,
        roomId,
        userId: user.uuid
      })
    });
  }

  function handleKicked() {
    toast.error((<small>You have been kicked from the room.</small>), {
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      draggable: false,
      theme: 'colored'
    });

    cancelJoin();
  }


  function handleMuted({ uuid, source, type = 'audio' }) {
    if ( user.uuid === uuid ) {
      updateSettings( (type === 'video' ? { videoEnabled: false } : { audioEnabled: false }) );
      const icon = type === 'video' ? 'fa-video-slash' : 'fa-microphone-slash';
      toast((<Toast title={false} icon={icon} message={`Your ${type} has been muted by ${source.name}`} />), {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        hideProgressBar: false,
        draggable: false,
        theme: 'light'
      });
    }
  }

  function handleNetworkDisconnect() {
    toast.error((<small>Connection lost.</small>), {
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      draggable: false,
      theme: 'colored'
    });

    cancelJoin();
  }

  const handleRoomConnect = useCallback(() => {
    enterRoomSound();
    syncMediaState();
  }, [users]);


  function syncMediaState() {
    if ( broadcast ){
      broadcast('LOCAL_STATE', {
        audio: audioEnabled,
        video: videoEnabled,
        orientation: 'landscape'
      });
    }
  }

  function presentJoinRequest(m){
    if( connected === true ){      
      roomRequestSound();
      toast(<ApproveMember peer={m} />, {
        toastId: m.uuid,
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false
      });
    }
  }

  function dismissJoinRequest(peer) {
    try {
      toast.dismiss(peer.uuid);
    }catch(e){}
  }


  function setupDocumentSession({ document, user }){
    if( connected === true ){      
      toast(<DocumentPrompt user={user} document={document} icon="fa-file-pdf" />, {
        toastId: document.uuid,
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false
      });
    }
  }

  useEffect(() => {
    subscribe('ROOM_CONNECT', handleRoomConnect);

    adapt.on('OFFLINE', handleNetworkDisconnect);
    adapt.room.on('MEMBER_APPROVED', dismissJoinRequest);
    adapt.room.on('MEMBER_CONNECT', dismissJoinRequest);
    adapt.room.on('KICKED', handleKicked);
    
    return _ => {
      adapt.off('OFFLINE', handleNetworkDisconnect);
      adapt.room?.off('MEMBER_APPROVED', dismissJoinRequest);
      adapt.room?.off('MEMBER_CONNECT', dismissJoinRequest);
      adapt.room?.off('KICKED', handleKicked);
    }
  }, []);

  useEffect(() => {
    let target;

    if( node.current ){
      target = node.current;
      target.addEventListener('dragover', handleDrag);
      target.addEventListener('drop', handleFiles);
    }

  return () => {
    target?.removeEventListener('dragover', handleDrag);
    target?.removeEventListener('drop', handleFiles);
    uploader.clear();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current]);


  useEffect(()=>{
    if( connected === true ){
      subscribe('CONNECTION_DROPPED', cancelJoin);
      subscribe('MUTE', handleMuted);
      subscribe('ROOM_DISCONNECT', cancelJoin);
      // subscribe('MEMBER_CONNECT', handleAutoPin);
      // subscribe('MEMBER_DISCONNET', handleAutoPin);
      subscribe('MEMBER_JOIN_REQUEST', presentJoinRequest);
      subscribe('DOCUMENT_SESSION', setupDocumentSession);
      subscribe('MEMBER_DISCONNECT', (peer) => {
        updateARState(peer.uuid, false);
      })
      subscribe('HL2_AR_CAMERA_FEED', handleARState);
    }
  }, [connected]);

  useEffect(() => {
    console.log(`Broadcast new media state: [audio: ${audioEnabled}, video: ${videoEnabled}]`);
    adapt.mediaStream?.getVideoTracks().forEach(t => {
      t.enabled = videoEnabled;
    });

    adapt.mediaStream?.getAudioTracks().forEach(t => {
      t.enabled = audioEnabled;
    });
    
    if ( connected === true ) {
      broadcast && broadcast('LOCAL_STATE', { audio: audioEnabled, video: videoEnabled, orientation: 'landscape' });
    }
  }, [audioEnabled, videoEnabled, broadcast]);

  useEffect(() => {
    console.log('Active AR feeds in room:', ARFeeds);
  }, [ARFeeds]);

  const currentTool = activeTool || 'call';
  const toolPanel   = (['screenshot', 'draw'].includes(currentTool) && AREnabled) || currentTool === 'pdf';


  if( state === 'InRoom' ){
    return (
      <>
        <Toolbar />
        <div id="main" ref={node}>
          <div id="left" data-active={currentTool}>
            <aside id="screen_tools" className={toolPanel ? 'open' : 'closed'}>
              { activeTool === 'screenshot' && AREnabled && <Screenshot /> }
              { activeTool === 'draw' && AREnabled && <Draw /> }
              <PDFViewer />
            </aside>
            <CallScreen compact={toolPanel} />
          </div>
          <Sidebar />
        </div>
        <BottomBar />
        <MediaView />
      </>
    )
  }

  return (
    <div id="connection_main">
      <div id="connect" className="p-4 bg-white rounded d-flex flex-column align-items-center justify-content-center shadow-sm">
        <Logo />
        <h4 className="pt-2 mb-1">Connecting to Room</h4>
        <p className="small mb-4">Please wait while someone lets you into the room.</p>
        <i className="fa fa-spin fa-spinner text-muted fa-2x mb-4"></i>
        <button type="button" className="btn btn-dark" onClick={cancelJoin}>Cancel</button>        
      </div>
    </div>
  )

  
}