import adapt from '@turnaroundfactor/common';
import { useSettings } from '@turnaroundfactor/react';
import React, { useEffect, useState, useRef } from 'react';
import getLocalStream from '../../utils/getLocalStream';
import localStore from '../../utils/localStore';
import getMediaDevices from '../..//utils/getMediaDevices';

function AVTest({ active, close }){
  const { settings, update: updateSettings } = useSettings();
  const { audioEnabled, videoEnabled, mediaAllowed, audioDevice, videoDevice } = settings;
  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);

  const node = useRef(null);

  function toggleAudio(val){
    const player = node.current;
    const stream = player.srcObject;
    if( stream && stream.getAudioTracks ){
      stream.getAudioTracks().forEach(t => t.enabled = val );
    }
    updateSettings({ audioEnabled: val });
    localStore.set({ audioEnabled: val });
  }

  function toggleVideo(val){
    const player = node.current;
    const stream = player.srcObject;
    if( stream && stream.getVideoTracks ){
      stream.getVideoTracks().forEach(t => t.enabled = val );
    }
    updateSettings({ videoEnabled: val });
    localStore.set({ videoEnabled: val });
  }

  function closeVideo(player){
    if( player && player.srcObject ){
      const stream = player.srcObject;
      if( stream && stream.getTracks ){
        stream.getTracks().forEach(t => t.enabled = false);
        player.srcObject = null;
      }
    }
  }

  async function setupVideo(player){
    const stream = await getLocalStream();

    if ( stream ) updateSettings({ mediaAllowed: true });
    if( player ){
      console.log('player');
      
      if ( player.srcObject ) {
        player.srcObject.getTracks().forEach(t => {
          t.enabled = false;
          t.stop()
        });
        player.srcObject = null;
      }

      player.srcObject = stream;
      if( stream && stream.getTracks ){
        stream.getVideoTracks().forEach(t => t.enabled = videoEnabled );
        stream.getAudioTracks().forEach(t => t.enabled = audioEnabled );
      }
    }      
  }

  useEffect(() => {

    async function getDevices() {
      const devices = await getMediaDevices();
      setCameras(devices.video);
      setMics(devices.audio);
    }

    adapt.on('STREAM_CAPTURE', getDevices);
    getDevices();

    return _ => adapt.off('STREAM_CAPTURE', getDevices);
  }, []);

  useEffect(() => {
    setupVideo(node.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDevice, audioDevice]);


  useEffect(() => {
    const player = node.current;

    if( active === true ){
      setupVideo(player);
    }else{
      closeVideo(player);
    }

    return ()=> closeVideo(player);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div id="avtest" className="container">
      {mediaAllowed === false && <div className="alert alert-danger small d-flex justify-content-between align-items-center">
        <span className="d-inline-block mr-2 me-2">
          <i className="fa fa-exclamation-circle fa-fw mr-1 me-1"></i> 
          Please allow audio and video permissions to utilize media features. 
        </span>
        <button type="button" className="btn btn-sm btn-danger" onClick={e => setupVideo(node.current)}>Try again</button>
      </div>}
      <div className="d-flex justify-content-center align-items-center mb-4">
        <h4 className="mb-0 text-center me-2 mr-2">Audio/Video Settings</h4>
        <button type="button" className="btn btn-xs btn-link text-muted" onClick={close}>&times; Close</button>
      </div>
      <div className="row justify-content-center align-items-md-center">
          <div className="col-xs-12 col-md-4 mb-4 mb-md-0 px-md-0">
            <figure className="embed-responsive embed-responsive-16by9 video">
              <video id="av_test_video" className="bg-black" autoPlay muted playsInline ref={node}></video>
            </figure>
          </div>
          <div className="col-xs-12 col-md-3 mb-md-0">
            <div className="d-flex align-items-center mb-2">
              <button type="button" className="btn btn-outline-light d-flex align-items-center w-100" onClick={e => toggleVideo(!videoEnabled)}>
                <i className={`fa fa-fw ${videoEnabled ? 'fa-video' : 'fa-video-slash text-danger'} mr-3`}/>
                <span className={`${videoEnabled ? '':'text-danger'}`}>Video { videoEnabled ? 'Enabled' : 'Disabled' }</span>
              </button>
            </div>
            <div className="d-flex align-items-center mb-4">
              <select value={videoDevice} onChange={e => updateSettings({ videoDevice: e.target.value })} className="form-control bg-dark text-white">
                {cameras.map((c, i) => 
                  <option key={i} value={c.id}>{c.label}</option>
                )}
              </select>
            </div>
            <div className="d-flex align-items-center mb-2">
              <button type="button" className="btn btn-outline-light d-flex align-items-center w-100" onClick={e => toggleAudio(!audioEnabled)}>
                <i className={`fa fa-fw ${audioEnabled ? 'fa-microphone' : 'fa-microphone-slash text-danger' } mr-3`}/>
                <span className={`${audioEnabled ? '':'text-danger'}`}>Audio { audioEnabled ? 'Enabled' : 'Disabled' }</span>
              </button>
            </div>
            <div className="d-flex align-items-center mb-4 mb-md-0">
              <select value={audioDevice} onChange={e => updateSettings({ audioDevice: e.target.value })} className="form-control bg-dark text-white">
                {mics.map((c, i) => 
                  <option key={i} value={c.id}>{c.label}</option>
                )}
              </select>
            </div>
          </div>
      </div>
    </div>
  )
}

export default AVTest;